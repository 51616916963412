export const mainScreenDonationOptions = [
  {
    amount: 20.0,
  },
  {
    amount: 50.0,
  },
  {
    amount: 100.0,
  },
  {
    amount: 200.0,
  },
];

export const readableInstNames = {
  salaam : "Salaam Islamic Center",
  anatolia: "Anatolia Cultural Center",
  pacifica: "Pacifica Institute"
}

export const apiBaseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5001/donation-kiosk-2fd5b/us-central1' : 'https://us-central1-donation-kiosk-2fd5b.cloudfunctions.net';
export const homeUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://donation-kiosk-ui.pages.dev';