import { mainScreenDonationOptions } from '../config';

export function MainScreen({ setValue, setNgo, value, ngo }) {
  return (
    <>
      {/*<div className='flex mb-6 justify-center mt-6'>*/}
      {/*  <button*/}
      {/*    className={`*/}
      {/*      bg-white shadow-lg rounded-lg px-12 flex justify-center items-center h-[102px] mx-4 text-4xl transition-all*/}
      {/*      ${*/}
      {/*        ngo === 'pacifica'*/}
      {/*          ? 'outline outline-green-600 outline-offset-4 outline-[6px] bg-green-50 font-bold text-green-600'*/}
      {/*          : 'text-slate-800'*/}
      {/*      }*/}
      {/*    `}*/}
      {/*    key={'pacifica'}*/}
      {/*    onClick={() => setNgo('pacifica')}*/}
      {/*  >*/}
      {/*    <img src='/pacifica.png' alt='logo-pacifica' className='inline-block' width={300} />*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*    className={`*/}
      {/*      bg-white shadow-lg rounded-lg px-12 flex justify-center items-center h-[102px] mx-4 text-4xl transition-all*/}
      {/*      ${*/}
      {/*        ngo === 'anatolia'*/}
      {/*          ? 'outline outline-green-600 outline-offset-4 outline-[6px] bg-green-50 font-bold text-green-600'*/}
      {/*          : 'text-slate-800'*/}
      {/*      }*/}
      {/*    `}*/}
      {/*    key={'anatolia'}*/}
      {/*    onClick={() => setNgo('anatolia')}*/}
      {/*  >*/}
      {/*    <img src='/anatolia.png' alt='logo-anatolia' className='inline-block' width={300} />*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*    className={`*/}
      {/*      bg-white shadow-lg rounded-lg px-12 flex justify-center items-center h-[102px] mx-4 text-4xl transition-all*/}
      {/*      ${*/}
      {/*        ngo === 'salaam'*/}
      {/*          ? 'outline outline-green-600 outline-offset-4 outline-[6px] bg-green-50 font-bold text-green-600'*/}
      {/*          : 'text-slate-800'*/}
      {/*      }*/}
      {/*    `}*/}
      {/*    key={'salaam'}*/}
      {/*    onClick={() => setNgo('salaam')}*/}
      {/*  >*/}
      {/*    <img src='/salaam.png' alt='logo-salaam' className='inline-block' width={300} />*/}
      {/*  </button>*/}
      {/*</div>*/}
      <h1 className='text-center py-8 text-4xl'>
        How much would you like to donate?
      </h1>

      <div className='mt-6 flex justify-center'>
        {mainScreenDonationOptions.map((option) => {
          return (
            <button
              className={`
                bg-white shadow-lg rounded-lg px-12 flex justify-center items-center h-[92px] mx-4 text-4xl transition-all
                ${
                  option.amount === value
                    ? 'outline outline-green-600 outline-offset-4 outline-[6px] bg-green-50 font-bold text-green-600'
                    : 'text-slate-800'
                }
              `}
              key={option.amount}
              onClick={() => setValue(option.amount)}
            >
              ${option.amount.toFixed(0)}
            </button>
          );
        })}
      </div>

      <p className='my-12 text-center text-3xl'>or enter custom amount</p>

      <div className='bg-white shadow-lg rounded-lg w-[300px] h-[92px] mx-auto text-2xl'>
        <div className='flex justify-center items-center'>
          <input
            className='outline-none pt-2 mx-8 min-w-full bg-transparent text-center text-6xl'
            type='number'
            maxLength={8}
            placeholder='$0'
            onInput={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
