import { useEffect, useState } from 'react';
import { ResultStates } from '../App';
import {readableInstNames, homeUrl} from "../config";

export function ResultScreen({ value, goMainScreen, ngo }) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const r = params.get('result');
    setResult(r);
  }, []);

  setTimeout(() => { window.location.href = homeUrl}, 5000);

  return (
    <>
      <h1 className='text-center py-5 text-5xl'>
        {result === ResultStates.Success
          ? 'Thank you!'
          : 'Payment could not be received'}
      </h1>

      <div className='bg-white rounded-lg shadow-xl w-[960px] mx-auto p-10 mt-4'>
        {result === ResultStates.Success && (
          <div className='flex justify-center items-center flex-col'>
            <img src='/img/success.svg' width={140} className='mb-8' />
            <p className='text-center font-medium'>
                We received your donation
            </p>
          </div>
        )}

        {result === ResultStates.Error && (
          <div className='flex justify-center items-center flex-col'>
            <img src='/img/error.svg' width={140} className='mb-8' />
            <p className='text-center font-medium'>
                We could not receive your donation
            </p>
          </div>
        )}

        <div className='flex justify-center'>
          <button
            onClick={goMainScreen}
            className='p-8 from-gray-500 to-gray-600 bg-gradient-to-b text-white rounded-lg mt-12'
          >
            Go Main Screen
          </button>
        </div>
      </div>
    </>
  );
}
