export function ArrowRightIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#FFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <line x1='5' y1='12' x2='19' y2='12'></line>
      <polyline points='12 5 19 12 12 19'></polyline>
    </svg>
  );
}
