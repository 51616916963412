import { ArrowRightIcon } from './components/arrow-right-icon';
import { useEffect, useState } from 'react';
import { MainScreen } from './screens/main-screen';
import { PaymentScreen } from './screens/payment-screen';
import { ResultScreen } from './screens/result-screen';
import {apiBaseUrl, homeUrl} from "./config";

const Screens = {
  MainScreen: 'MainScreen',
  PaymentScreen: 'PaymentScreen',
  ResultScreen: 'ResultScreen',
};

export const ResultStates = {
  Success: 'success',
  Error: 'error',
};

function App() {
  const [value, setValue] = useState('');
  const [tab, setTab] = useState(Screens.MainScreen);
  const [ngo, setNgo] = useState('anatolia');

  const onNextBtnClick = async () => {
    if (value < 0.5) {
      alert("Your donation must be equal to or bigger than 50 cent")
      return
    }

    if (tab === Screens.MainScreen) {
      setTab(Screens.PaymentScreen);
    }
    try {
     const res = await fetch(`${apiBaseUrl}/setReader?amount=${value*100}&nonProfit=${ngo}`)
      // cancelled payment
     if (res.status === 499) return
     if (res.ok) {
       window.location.href = `${homeUrl}/?result=success`
     } else {
       window.location.href = `${homeUrl}/?result=error`
     }
    } catch (e) {
      console.log({e})
      window.location.href = `${homeUrl}/?result=error`
    }
  };

  const onCancel = async () => {
    try {
      await fetch(`${apiBaseUrl}/cancelReader/?nonProfit=${ngo}`)
    } catch (e) {
      console.log({e})
    }
    if (tab === Screens.PaymentScreen) {
      setTab(Screens.MainScreen);
      setValue('')
    }
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const result = params.get('result');

    if (result && Object.values(ResultStates).includes(result)) {
      setTab(Screens.ResultScreen);
    } else {
      setTab(Screens.MainScreen);
      window.history.pushState({}, document.title, '/');
    }
  }, [window.location?.search]);

  return (
    <div className='App min-h-screen bg-[#edf2f6] border-t-4 border-gray-900 py-10 text-slate-800 text-4xl'>
      <div className='text-center mt-2 mb-6'>
        <img src='/anatolia.png' alt='logo' className='inline-block' width={400} />
      </div>

      {tab === Screens.MainScreen && (
        <MainScreen setValue={setValue} value={value} ngo={ngo} setNgo={setNgo} />
      )}
      {tab === Screens.PaymentScreen && <PaymentScreen value={value} ngo={ngo} />}
      {tab === Screens.ResultScreen && (
        <ResultScreen
          goMainScreen={() => {
            setTab(Screens.MainScreen);
            window.history.pushState({}, document.title, '/');
          }}
          value={value}
          ngo={ngo}
        />
      )}

      {value && tab === Screens.PaymentScreen && (
        <button
          className='flex font-medium items-center justify-center px-10 fixed left-0 bottom-[6vh] from-gray-500 to-gray-600 bg-gradient-to-b rounded-tr-lg rounded-br-lg h-[18vh] text-white shadow-2xl'
          onClick={onCancel}
        >
          <span className='mr-5'>Cancel</span>
        </button>
      )}

      {value && tab === Screens.MainScreen && (
        <button
          className='flex font-medium items-center justify-center px-10 fixed right-0 bottom-[6vh] from-green-500 to-green-600 bg-gradient-to-b rounded-tl-lg rounded-bl-lg h-[18vh] text-white shadow-2xl'
          onClick={onNextBtnClick}
        >
          <span className='mr-5'>
            Donate <br />${value}
          </span>
          <ArrowRightIcon />
        </button>
      )}
    </div>
  );
}

export default App;
