export function PaymentScreen({ value, ngo }) {
  return (
    <>
      {/*<h1 className='text-center py-8 text-6xl'>Swipe, insert, or tap</h1>*/}
      {/*<div className='text-center mb-6'>*/}
      {/*  <img src={`/${ngo}.png`} alt='logo' className='inline-block' width={300} />*/}
      {/*</div>*/}

      <div className='bg-white rounded-lg shadow-xl w-[840px] mx-auto p-6 mt-8'>
        <p className='text-center mb-6 text-4xl font-medium'>Amount: ${value}</p>
        <img src='/img/swipe.png' className='max-w-full' alt='swipe' />
      </div>
    </>
  );
}
